<template>
	<div class="w-full bg-base-100">      
		
		<div class="overflow-x-auto">
			<table class="table">            
				<thead>
					<tr>								
						<th class="pl-20">Product</th>								
						<th class="w-32">Price</th>            
						<th class="w-32">Quantity</th>
					</tr>
				</thead>
				<tbody>              
					<tr v-for="product in tempProducts" v-bind:key="product.id">                        
						<td>									
							<div class="flex items-center space-x-4">
								<div class="rounded-lg w-12 h-12 bg-gray-300">
									<img :src=" product.photo || ''"/>
								</div>
								<div>
									{{product.name}}
								</div>
							</div>
						</td>							
						<td>
							${{product.price}}
						</td>   
						<td>									
							<input v-model="product.quantity" type="number" placeholder="0" class="input input-sm input-bordered w-full" />
						</td>           
					</tr>
					<tr>                        
						<th>
							Total                          
						</th>								
						<th>
							{{tempProducts.length > 0 ? tempProducts.map( p => p.quantity).reduce((a, b) => a + b) : 0}}                   
						</th>
						<th>
							${{tempProducts.length > 0 ? tempProducts.map( p => p.quantity * p.price).reduce((a, b) => a + b) : 0}}                   
						</th>              
					</tr>
				</tbody>                               
			</table>

		</div>
	</div>

	<div class="pt-6 flex-none">
		<button @click="handler(tempProducts)" class="btn btn-success btn-sm w-full text-white no-animation">Create order</button>
	</div>
</template>

<script>
	import { onMounted, ref } from "vue"		
	// import { XMarkIcon } from '@heroicons/vue/24/outline' 		

	export default {
		name: "NewOrderComponent",
		components: {
			// XMarkIcon
		},	
		props: {
			products: Object,
			handler: Function,			
		},
		setup(props) {
			const tempProducts = ref({})			

			onMounted( async () => {            
				tempProducts.value = JSON.parse(JSON.stringify(props.products))
			})				
			
			return {
				tempProducts,
				props								
			}
		}
	};
</script>

<template>
	<div class="w-full h-full overflow-hidden">
		<div class="drawer h-full overflow-hidden">
			<input id="my-drawer-3" type="checkbox" class="drawer-toggle" /> 
			<div class="h-full overflow-hidden drawer-content flex flex-col">
				<div class="flex h-full overflow-hidden">
					<div class="hidden lg:block w-56 h-full bg-base-200" v-if="showNav">						
						<div class="flex flex-col h-full justify-between">
							<div>
								<div class="h-16 flex items-center">
									<div class="flex-1 px-4 mx-2">Poink</div>
								</div>
								<ul v-if="route && route.name && route.name.startsWith('Client_')" class="menu px-2 w-56 h-full ">
									<li>
										<router-link :class="{ 'bg-base-300': route.name.includes('Client_Home') || route.name.includes('Client_Order') }" v-if="route && route.params.client_id" :to="`/client/${route.params.client_id}/inbox`">
											<InboxIcon class="h-5 w-5" aria-hidden="true" />  
											Orders
										</router-link>
									</li>
									<li>
										<router-link class="mt-4" :class="{ 'bg-base-300': route.name.includes('Client_New_Order') }" v-if="route && route.params.client_id" :to="`/client/${route.params.client_id}/order`">
											<PlusIcon class="h-5 w-5" aria-hidden="true" />  
											New order
										</router-link>
									</li>
								</ul>
								<ul v-if="route && route.name && route.name.startsWith('Provider_')" class="pl-2 menu w-56 h-full">
									<li>
										<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Home') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/inbox`">
											<InboxIcon class="h-5 w-5" aria-hidden="true" />  
											Inbox
										</router-link>
									</li>
									<li>
										<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Orders') || route.name.includes('Provider_Order') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/orders`">
											<CircleStackIcon class="h-5 w-5" aria-hidden="true" />  
											Orders
										</router-link>
									</li>
									<li>
										<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Products') || route.name.includes('Provider_Product') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/products`">
											<ShoppingCartIcon class="h-5 w-5" aria-hidden="true" />  
											Products
										</router-link>
									</li>            
									<li>
										<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Clients') || route.name.includes('Provider_Client') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/clients`">
											<UsersIcon class="h-5 w-5" aria-hidden="true" />  
											Clients
										</router-link>
									</li>
								</ul> 
							</div>
							<div>
								<ul v-if="route && route.name && route.name.startsWith('Provider_')" class="pl-2 menu w-56 h-full">
									<li>
										<div v-if="pro" @click="getBillingLink()">
											<CreditCardIcon class="h-5 w-5" aria-hidden="true" />  									
											Billing
										</div>
										<div v-else @click="showModal()">									
											<SparklesIcon class="h-5 w-5" aria-hidden="true" />  
											Poink Pro
										</div>
									</li>

									<li>
										<div @click="logout()">
											<ArrowRightStartOnRectangleIcon class="h-5 w-5" aria-hidden="true" />  
											Logout
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="w-full h-full flex flex-col overflow-hidden"> 
						<div class="w-full navbar flex lg:hidden" v-if="showNav">
							<div class="flex-none block lg:hidden">
								<label for="my-drawer-3" aria-label="open sidebar" class="btn btn-square btn-ghost no-animation">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
								</label>
							</div> 
							<div class="flex-1 px-2 mx-2">
								<p class="block lg:hidden">
									Poink
								</p>
							</div>
						</div>
						<router-view class="pt-0 lg:pt-6 h-full overflow-y-scroll"/>
					</div>
				</div>
			</div> 
			<div class="drawer-side">
				<label for="my-drawer-3" aria-label="close sidebar" class="drawer-overlay"></label>       
				<div @click="toggle" class="w-80 flex flex-col h-full bg-base-200">   
					
					<div class="flex flex-col h-full justify-between">
						<div class="flex items-center" style="height:69px !important">
							<div class="flex-1 px-4" >
								<p class="">
									Poink
								</p>
							</div>
						</div>
						<ul v-if="route && route.name && route.name.startsWith('Client_')" class="menu px-2 w-80 h-full ">
							<li>
								<router-link :class="{ 'bg-base-300': route.name.includes('Client_Home') || route.name.includes('Client_Order') }" v-if="route && route.params.client_id" :to="`/client/${route.params.client_id}/inbox`">
									<InboxIcon class="h-5 w-5" aria-hidden="true" />  
									Orders
								</router-link>
							</li>
							<li>
								<router-link class="mt-4"  :class="{ 'bg-base-300': route.name.includes('Client_New_Order') }" v-if="route && route.params.client_id" :to="`/client/${route.params.client_id}/order`">
									<PlusIcon class="h-5 w-5" aria-hidden="true" />  
									New order
								</router-link>
							</li>
						</ul>
						<ul v-if="route && route.name && route.name.startsWith('Provider_')" class="menu px-2 w-80 h-full ">
							<li>
								<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Home') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/inbox`">
									<InboxIcon class="h-5 w-5" aria-hidden="true" />  
									Inbox
								</router-link>
							</li>
							<li>
								<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Orders') || route.name.includes('Provider_Order') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/orders`">
									<CircleStackIcon class="h-5 w-5" aria-hidden="true" />  
									Orders
								</router-link>
							</li>
							<li>
								<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Products') || route.name.includes('Provider_Product') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/products`">
									<ShoppingCartIcon class="h-5 w-5" aria-hidden="true" />  
									Products
								</router-link>
							</li>            
							<li>
								<router-link :class="{ 'bg-base-300': route.name.includes('Provider_Clients') || route.name.includes('Provider_Client') }" v-if="route && route.params.provider_id" :to="`/provider/${route.params.provider_id}/clients`">
									<UsersIcon class="h-5 w-5" aria-hidden="true" />  
									Clients
								</router-link>
							</li>
						</ul>
					</div>
					<div>
						<ul v-if="route && route.name && route.name.startsWith('Provider_')" class="pl-2 menu w-80 h-full">
							<li>
								<div v-if="pro" @click="getBillingLink()">
									<CreditCardIcon class="h-5 w-5" aria-hidden="true" />  									
									Billing
								</div>
								<div v-else @click="showModal()">									
									<SparklesIcon class="h-5 w-5" aria-hidden="true" />  
									Poink Pro
								</div>
							</li>						
							<li>
								<div @click="logout()">
									<ArrowRightStartOnRectangleIcon class="h-5 w-5" aria-hidden="true" />  
									Logout
								</div>
							</li>
						</ul>
					</div>
				</div>			
			</div>
		</div>    
	</div>
	
	<dialog id="my_modal_3" class="modal">
		<div class="modal-box">
			<form method="dialog">
				<button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
			</form>
			<h3 class="text-lg font-bold">Unlock more with Poink Pro</h3>
			<ul class="pt-2">
				<li>- Give your clients each their own link so they can place orders</li>
				<li>- Add photos to your products</li>
				<li>- Unlimited orders</li>
			</ul>

			<p class="py-4">$19/month</p>

			<button class="btn btn-primary text-white no-animation btn-sm w-full" @click="getBillingLink">Subscribe</button>
		</div>
	</dialog>

</template>

<script>
	import { InboxIcon, ShoppingCartIcon, CircleStackIcon, UsersIcon, PlusIcon, ArrowRightStartOnRectangleIcon, SparklesIcon, CreditCardIcon } from '@heroicons/vue/24/outline' 
	import { useRoute, useRouter } from 'vue-router'
	import { onMounted, computed, ref } from "vue"  
	import { useStore } from 'vuex'
	import { useLoading } from 'vue-loading-overlay'
	import axios from "@/axios.js"

	export default {
		name: "App",  
		components: {
			InboxIcon, ShoppingCartIcon, CircleStackIcon, UsersIcon, PlusIcon, ArrowRightStartOnRectangleIcon, SparklesIcon, CreditCardIcon
		},
		setup() {
			const store = useStore()
			const route = useRoute()
			const router = useRouter()
			const user = ref(null)
			const pro = ref(false)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner",
				"z-index": 99999
			});

			//Eventually use Sparkles and Go Pro but for now just billing

			onMounted( async () => {
				try {
					let isPro = await getPro()
					pro.value = isPro
					console.log('PRO', isPro)
					store.commit("setPro", {
						pro: isPro,
						date: new Date()
					})            
				} catch (e) {
					console.log(e)
				}

				try {
					let session = JSON.parse(localStorage.getItem('enlazado_session'));        
					store.commit("setSession", session)            
				} catch (e) {
					console.log(e)
				}
			})

			async function getPro() {
				let session = JSON.parse(localStorage.getItem('enlazado_session'));        
				
				// let loader = loading.show()

				axios({
					method: 'get',
					url: '/api/auth/pro',
					params: { 
						user_id: session.user.user.id, 
						provider: route.params.provider_id
					}
				})				
				.then( res => {				
					console.log("Pro", res)	
					pro.value = res.data.pro
					return res.data.pro
				})
				.catch( e => {
					// loader.hide()
					alert("Please try again")
					console.log(e)
				})
			}

			function getBillingLink() {
				let session = JSON.parse(localStorage.getItem('enlazado_session'));        
				
				let loader = loading.show()

				axios({
					method: 'get',
					url: '/api/auth/billing',
					params: { 
						user_id: session.user.user.id, 
						provider: route.params.provider_id
					}
				})				
				.then( res => {
					loader.hide()
					console.log("Response", res)	
					window.location.href = res.data.url				
				})
				.catch( e => {
					loader.hide()
					alert("Please try again")
					console.log(e)
				})
			}

			const showNav = computed( () => {
				if (route.path == "/") {
					return false
				} else if (route.name == "Login") {
					return false
				} else if (route.name == "Signup") {
					return false
				} else if (route.name == "Reset Password") {
					return false
				} else {
					return true
				}
			})

			function toggle() {
				var box = document.getElementById("my-drawer-3");
				box.checked= !box.checked				
			}

			async function logout() {
				await localStorage.setItem('enlazado_session', null)
				await store.commit("setSession", null)   
				router.push('/login')         				
				router
			}

			function showModal() {
				document.getElementById('my_modal_3').showModal()
			}

			return {
				session: computed(() => store.state.session),
				route,
				showNav,
				user,
				toggle,
				logout,
				showModal,
				getBillingLink,
				pro
			}
		}
	};
</script>
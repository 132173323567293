<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full">
		<div class="flex justify-between items-center pb-4">
			<div class="flex space-x-4">						
				<button class="btn btn-square btn-outline btn-sm no-animation" @click="goBack(router, getBackLink)">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0" stroke="currentColor" class="w-5 h-5">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
				</button>
				<div class="text-2xl font-bold">Order {{route.params.order_id}}</div>
			</div>
			<div class="flex space-x-4">
				<div class="text-xl" v-if="order">{{new Date(order.created_at).toLocaleDateString()}}</div>
				<!-- <div class="text-xl skeleton w-16 h-8" v-if="!order"></div> -->
				<!-- <div class="text-xl skeleton w-12 h-8" v-if="!order"></div> -->
				<StatusComponent :order="order"></StatusComponent>
			</div>
		</div>

		<div class="grid grid-cols-1 md:grid-cols-3 space-x-0 md:space-x-8">
			<div class="col-span-2" >
				<div class="pb-2 font-bold text-xl">Order details</div>
				<div class="w-full bg-base-100 border-t pt-2">      
					
					<div class="overflow-x-auto">
						<table v-if="!order" class="table">            
							<thead>
								<tr>								
									<th class="pl-20">Product</th>
									<th class="w-32">Quantity</th>
									<th class="w-32">Price</th>            
								</tr>
							</thead>
							<tbody>              
								<tr>                        
									<td>																		
										<div>
											<div class="flex items-center space-x-4">
												<div class="skeleton rounded-lg w-12 h-12 bg-gray-300">

												</div>
												<div>
													<div class="skeleton w-12 h-5"></div>
												</div>
											</div>
										</div>
									</td>
									<td>
										<div class="skeleton w-12 h-5"></div>
									</td>
									<td>
										<div class="skeleton w-12 h-5"></div>
									</td>              
								</tr>
								<tr>                        
									<th>
										Total                          
									</th>								
									<th>
										<div class="skeleton w-12 h-5"></div>     
									</th>
									<th>
										<div class="skeleton w-12 h-5"></div>            
									</th>              
								</tr>
							</tbody>                               
						</table>

						<table v-if="order" class="table">            
							<thead>
								<tr>								
									<th class="pl-20">Product</th>
									<th class="w-32">Quantity</th>
									<th class="w-32">Price</th>            
								</tr>
							</thead>
							<tbody>              
								<tr v-for="line_item in order.line_items" v-bind:key="line_item.id" class="hover:bg-base-200">                        
									<td>
										<router-link  v-if="user === 'Provider'" class="cursor-pointer" :to="`/provider/${route.params.provider_id}/product/${line_item.product.id}`">
											<div class="flex items-center space-x-4">
												<div class="rounded-lg w-12 h-12 bg-gray-300 overflow-hidden">
													<img :src=" line_item.product.photo || ''"/>
												</div>
												<div>
													{{line_item.product.name}}
												</div>
											</div>
										</router-link>

										<div v-else>
											<div class="flex items-center space-x-4">
												<div class="rounded-lg w-12 h-12 bg-gray-300 overflow-hidden">
													<img :src=" line_item.product.photo || ''"/>
												</div>
												<div>
													{{line_item.product.name}}
												</div>
											</div>
										</div>
									</td>
									<td>
										{{line_item.quantity}}
									</td>
									<td>
										${{line_item.product.price}}
									</td>              
								</tr>
								<tr>                        
									<th>
										Total                          
									</th>								
									<th>
										{{order.line_items.map( li => li.quantity).reduce((a, b) => a + b)}}                   
									</th>
									<th>
										${{order.line_items.map( li => li.quantity * li.price).reduce((a, b) => a + b)}}                   
									</th>              
								</tr>
							</tbody>                               
						</table>						
					</div>
				</div>
			</div>
			<div class="col-span-1 pt-8 md:pt-0">
				<div v-if="user === 'Provider'" class="pb-8">
					<div class="pb-2 text-xl font-bold">Customer</div>
					<router-link v-if="order" class="cursor-pointer" :to="`/provider/${route.params.provider_id}/client/${order.client.id}`">
					<div class="w-full bg-base-100 border-t pt-2">   
						<div>
							<p class="text-sm text-gray-400">Name</p>
							<p class="text-sm" v-if="order">{{order.client.name}}</p>
							<p class="text-sm w-12 h-5 skeleton" v-if="!order"></p>	
						</div>						
						<div class="mt-2">
							<p class="text-sm text-gray-400">Email</p>
							<p class="text-sm" v-if="order">{{order.client.email}}</p>
							<p class="text-sm w-12 h-5 skeleton" v-if="!order"></p>	
						</div>
						<div v-if="order && order.client.address" class="mt-2">
							<p class="text-sm text-gray-400">Address</p>
							<p class="text-sm" v-if="order">{{order.client.address}}</p>
							<p class="text-sm w-12 h-5 skeleton" v-if="!order"></p>	
						</div>            						
					</div>
					</router-link>
				</div>
				<div v-if="user === 'Client'" class="pb-8">
					<div class="pb-2 text-xl font-bold">Provider</div>
					<div class="w-full bg-base-100 border-t pt-2">   
						
						<div>
							<p class="text-sm text-gray-400">Name</p>
							<p class="text-sm" v-if="order">{{order.provider.name}}</p>
							<p class="text-sm w-12 h-5 skeleton" v-if="!order"></p>	
						</div>							
						<div v-if="order && order.provider.email" class="mt-2">
							<p class="text-sm text-gray-400">Email</p>
							<p class="text-sm" v-if="order">{{order.provider.email}}</p>
							<p class="text-sm w-12 h-5 skeleton" v-if="!order"></p>	
						</div>            
						
					</div>
				</div>
				
				<div v-if="user === 'Provider'">
					<div class="pb-2 text-xl font-bold">Actions</div>
					<div v-if="order" class="bg-base-100 border-t pt-2 flex-col flex space-y-2">   
						
						<button v-if="!order.cancelled && !order.fulfilled" class="btn btn-success btn-sm text-white no-animation" @click="handler({fulfilled: true})">Fullfill</button>
						<button v-else-if="!order.cancelled" class="btn btn-error btn-sm text-white no-animation" @click="handler({cancelled: true})">Cancel</button>     				
						<button class="btn btn-outline btn-sm no-animation" @click="copyLink(order)">Copy link</button>     
						
					</div>
				</div>
				<div v-if="user === 'Client'">
					<div class="pb-2 font-bold text-xl">Actions</div>
					<div v-if="order" class="bg-base-100 border-t pt-2 flex-col flex space-y-2">   
						<button v-if="!order.fulfilled && !order.cancelled" class="btn btn-error btn-sm text-white no-animation" @click="handler({cancelled: true})">Cancel</button>     
						<div v-else>There are no actions for this order.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import StatusComponent from "@/components/StatusComponent.vue";		
	import { useRoute, useRouter } from 'vue-router'
	import { computed } from 'vue'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "OrderComponent",	
		components: {
			StatusComponent	
		},
		props: {
			user: String,
			order: Object,
			handler: Function,	
			goBack: Function		
		},
		setup(props) {						
			const route = useRoute()
			const router = useRouter()

			const getBackLink = computed( () => {
				if (props.user === "Provider") {
					return `/provider/${route.params.provider_id}/inbox`
				} else {
					return `/client/${route.params.client_id}`
				}
			})

			function copyLink(order) {	
				let link = `${window.location.origin}/client/${order.client.id}/order/${order.id}`
				navigator.clipboard.writeText(link);
				useToast().success('Enlace copiado', { position: "top-right" });				
			}

			return {	
				StatusComponent,
				route,
				router,
				getBackLink,
				copyLink

			}
		}
	};
</script>



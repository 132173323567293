<template>
	<div class="drawer drawer-end">		
		<input id="_drawer" type="checkbox" class="drawer-toggle" />
		<div class="drawer-content">
			<div class="p-4 lg:p-6 pt-0 lg:pt-0 h-full">
				<div class="flex justify-between items-center pb-4">
					<div class="flex space-x-4">				
						<button class="btn btn-square btn-outline btn-sm no-animation" @click="utils.goBack(router, `/provider/${route.params.provider_id}/clients`)">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0" stroke="currentColor" class="w-5 h-5">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
							</svg>
						</button>
						<div class="text-2xl font-bold">Client</div>
					</div>
					<label v-if="client" for="_drawer" class="btn btn-square btn-outline btn-sm no-animation">
						<PencilIcon  class="h-5 w-5" aria-hidden="true" />  					
					</label>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 space-x-0 md:space-x-8">
					<div class="col-span-2">						
						<div class="pb-2 font-bold text-xl">Details</div>
						<div class="w-full bg-base-100 border-t pt-2">      
							
							<div>
								<p class="text-sm text-gray-400">Name</p>
								<p class="text-sm" v-if="client">{{client.name}}</p>
								<p class="text-sm w-12 h-5 skeleton" v-if="!client"></p>
							</div>
							<div class="mt-2">
								<p class="text-sm text-gray-400">Email</p>
								<p class="text-sm" v-if="client">{{client.email}}</p>
								<p class="text-sm w-12 h-5 skeleton" v-if="!client"></p>	
							</div>								

						</div>

						<div class="pb-2 pt-8 font-bold text-xl">Recent Orders</div>
						<div class="w-full bg-base-100 border-t pt-2">      							
							<div v-if="!orders" class="overflow-x-auto">
								<table class="table">            
									<thead>
										<tr class="">              
											<th>Order</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>              
										<tr class="">   
											<td class="w-1/2">													
												<div class="w-12 h-5 skeleton"></div>												
											</td>
											<td class="w-1/2">
												<div class="w-12 h-5 skeleton"></div>		
											</td>											
										</tr>
									</tbody>                               
								</table>
							</div>
							<div v-if="orders && orders.length == 0">
								There are no recent orders for this client
							</div>
							<div v-if="orders && orders.length > 0" class="overflow-x-auto">
								<table class="table">            
									<thead>
										<tr class="cursor-pointer">              
											<th>Order</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>              
										<tr v-for="order in orders" v-bind:key="order.id" class="hover:bg-base-200 cursor-pointer" @click="goTo(order)">   
											<td class="w-1/2">													
												{{order.id}}													
											</td>
											<td class="w-1/2">
												{{new Date(order.created_at).toLocaleDateString()}}
											</td>											
										</tr>
									</tbody>                               
								</table>
							</div>							
						</div>
					</div>					
					<div class="col-span-1 pt-8 md:pt-0">
						<div class="pb-2 font-bold text-xl">Actions</div>
						<div class="bg-base-100 border-t pt-2 flex flex-col space-y-2">      
							<div class="label cursor-pointer">
								<span class="label-text text-sm">Active</span> 
								<input v-if="providerClient" @change="updateProviderClient()" v-model="providerClient.active_temp" type="checkbox" class="toggle" checked />
								<input v-if="!providerClient" type="checkbox" class="toggle skeleton" />
							</div>
							<button v-if="client" class="btn btn-outline btn-sm w-full no-animation" @click="copyLink()">Copy link</button>     
							
						</div>
					</div>				
				</div>
			</div>
		</div> 
		<ClientDrawer v-if="client" :client="client" :handler="updateClient" :toggle="toggle"></ClientDrawer>
	</div>

</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PencilIcon } from '@heroicons/vue/24/outline' 
	import utils from "@/helpers/utils.js"
	import ClientDrawer from '@/components/ClientDrawer.vue' 
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Client",	
		components: {
			PencilIcon, ClientDrawer			
		},		
		setup() {
			const route = useRoute()
			const router = useRouter()

			const orders = ref(null)
			const client = ref(null)			
			const providerClient = ref(null)		

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/client/${route.params.client_id}`)
				.then( res => { 										
					client.value = res.data.client									
				})
				.catch(e => {
					console.log(e)
				}) 

				axios.get(`/api/provider/${route.params.provider_id}/provider_client?client_id=${route.params.client_id}`)
				.then( res => { 	
				console.log("providerClient", res)									
					providerClient.value = res.data.provider_client		
					providerClient.value.active_temp = res.data.provider_client.active						
				})
				.catch(e => {
					console.log(e)
				}) 

				axios.get(`/api/provider/${route.params.provider_id}/orders?client_id=${route.params.client_id}`)
				.then( res => { 
					orders.value = res.data.orders
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			async function updateClient(updatedClient) {
				if (!updatedClient.name) {
					return useToast().warning('A name is required for a client.', { position: "top-right" });
				}

				if (!updatedClient.email) {
					return useToast().warning('A email is required for a client.', { position: "top-right" });
				}	

				let data = {					
					name: updatedClient.name,
					email: updatedClient.email,
				}	

				let loader = loading.show()
				axios.put(`/api/provider/${route.params.provider_id}/client/${route.params.provider_client_id}`, data)
				.then( res => { 			
					loader.hide()		
					useToast().success('The client has been updated.', { position: "top-right" });
					client.value = res.data.client

					client.value.name_temp = res.data.client.name
					client.value.email_temp = res.data.client.email					
					toggle()
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error updating the client, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			} 

			async function updateProviderClient() {
				let data = {					
					active: providerClient.value.active_temp					
				}

				let loader = loading.show()
				axios.put(`/api/provider/${route.params.provider_id}/provider_client/${providerClient.value.id}`, data)
				.then( res => { 				
					loader.hide()		
					useToast().success('The client has been updated.', { position: "top-right" });	
					providerClient.value = res.data.providerClient	
					providerClient.value.active_temp = res.data.providerClient.active										
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error updating the client, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			} 

			function toggle() {
				const checkbox = document.getElementById('_drawer');
				checkbox.checked = !checkbox.checked;
			}

			function goTo(order) {
				router.push(`/provider/${route.params.provider_id}/order/${order.id}`)				
			}

			function copyLink() {	
				let link = `${window.location.origin}/client/${client.value.id}/`
				navigator.clipboard.writeText(link);
				useToast().success('Enlace copiado', { position: "top-right" });				
			}

			return {
				route,
				router,
				utils,
				client,
				providerClient,
				orders,
				toggle,
				updateClient,
				updateProviderClient,
				goTo,
				copyLink
			}
		}
	};
</script>

<template>
	<div class="drawer drawer-end ">	
		<input id="product_drawer" type="checkbox" class="drawer-toggle" />
		<div class="drawer-content">
			<div class="p-4 lg:p-6 pt-0 lg:pt-0 h-full">
				<div class="flex justify-between items-center pb-4">									
					<div class="text-2xl font-bold">Products</div>					
					<label for="product_drawer" class="btn btn-square btn-outline btn-sm no-animation">
						<PlusIcon  class="h-5 w-5" aria-hidden="true" />  					
					</label>
				</div>

				<div class="w-full bg-base-100" v-if="!products">      					
					<div class="overflow-x-auto">
						<table class="table">            
							<thead>
								<tr>								
									<th class="pl-20">Product</th>
									<th>Price</th>
									<th>Availability</th>            
								</tr>
							</thead>
							<tbody>              
								<tr class="">
									<td class="w-1/2">
										<div class="flex items-center space-x-4">											
											<div class="rounded-lg w-12 h-12 aspect-square bg-gray-300 skeleton">
												<div class=" relative bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden" style=" aspect-ratio: 1 / 1 !important;">	
												</div>					
											</div>
											<div class="skeleton w-12 h-5"></div>
										</div>
									</td>
									<td class="w-1/4">
										<div class="skeleton w-12 h-5"></div>
									</td>
									<td class="w-1/4">
										<div class="skeleton w-12 h-5"></div>
									</td>								
								</tr>							
							</tbody>                               
						</table>						
					</div>
				</div>

				<div class="card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100" v-if="products && products.length == 0">     
					<div class="card-body text-center">
						Add your first product using the plus button in the top-right corner
					</div>
				</div>

				<div class="w-full bg-base-100" v-if="products && products.length > 0">      					
					<div class="overflow-x-auto">
						<table class="table">            
							<thead>
								<tr>								
									<th class="pl-20">Product</th>
									<th>Price</th>
									<th>Availability</th>            
								</tr>
							</thead>
							<tbody>              
								<tr v-for="product in products.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))" v-bind:key="product.id" class="cursor-pointer hover:bg-base-200" @click="goTo(product)">
									<td class="w-1/2">
										<div class="flex items-center space-x-4">											
											<div class="rounded-lg w-12 h-12 aspect-square bg-gray-300">
												<div class="relative bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden" style=" aspect-ratio: 1 / 1 !important;" v-bind:style="{ backgroundImage: 'url(' + product.photo + ')' }">	
												</div>					
											</div>
											<div>
												{{product.name}}
											</div>
										</div>
									</td>
									<td class="w-1/4">
										${{product.price}}
									</td>
									<td class="w-1/4">
										{{product.active ? "Active" : "Inactive"}}
									</td>								
								</tr>							
							</tbody>                               
						</table>						
					</div>
				</div>
			</div>
		</div>
		<ProductDrawer v-if="product" :product="product" :handler="createProduct" :toggle="toggle"></ProductDrawer>	
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PlusIcon } from '@heroicons/vue/24/outline' 
	import ProductDrawer from '@/components/ProductDrawer.vue' 
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Products",			
		components: {
			PlusIcon, ProductDrawer
		},	
		setup() {
			const route = useRoute()
			const router = useRouter()

			const products = ref(null)
			const product = ref({})

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {    				           
				axios.get(`/api/provider/${route.params.provider_id}/products`)
				.then( res => { 					
					products.value = res.data.products
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			function goTo(product) {
				router.push(`/provider/${route.params.provider_id}/product/${product.id}`)
			}

			function toggle() {
				const checkbox = document.getElementById('product_drawer');
				checkbox.checked = !checkbox.checked;
			}

			async function createProduct(newProduct) {
				if (!newProduct.name) {
					return useToast().warning('A name is required for a product.', { position: "top-right" });
				}

				if (!newProduct.price) {
					return useToast().warning('A price is required for a product.', { position: "top-right" });
				}	

				let data = {
					price: newProduct.price,
					name: newProduct.name,
					photo: newProduct.photo,
					provider: route.params.provider_id,
					active: true
				}	

				let loader = loading.show()
				axios.post(`/api/provider/${route.params.provider_id}/product/`, data)
				.then( res => { 		
					product.value = null
					products.value.push(res.data.product)
					useToast().success('The product has been created.', { position: "top-right" });
					loader.hide()
					toggle()	

				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error creating the product, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			} 

			return {
				route,
				product,
				products,
				goTo,
				PlusIcon,
				toggle,
				createProduct,
				ProductDrawer
			}
		}
	};
</script>

<template>
	<div class="drawer drawer-end">		
		<input id="product_drawer" type="checkbox" class="drawer-toggle" />
		<div class="drawer-content">
			<div class="p-4 lg:p-6 pt-0 lg:pt-0 h-full">
				<div class="flex justify-between items-center pb-4">
					<div class="flex space-x-4">				
						<button class="btn btn-square btn-outline btn-sm no-animation" @click="utils.goBack(router, `/provider/${route.params.provider_id}/inbox`)">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0" stroke="currentColor" class="w-5 h-5">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
							</svg>
						</button>
						<div class="text-2xl font-bold">Product</div>
					</div>
					<label v-if="product" for="product_drawer" class="btn btn-square btn-outline btn-sm no-animation">
						<PencilIcon  class="h-5 w-5" aria-hidden="true" />  					
					</label>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 space-x-0 md:space-x-8">
					<div class="col-span-2">
						<div class="pb-2 font-bold text-xl">Details</div>
						<div class="w-full bg-base-100  border-t pt-2">      							
							<div>
								<p class="text-sm text-gray-400">Name</p>
								<p class="text-sm" v-if="product">{{product.name}}</p>
								<p class="text-sm w-12 h-5 skeleton" v-if="!product"></p>
							</div>
							<div class="mt-2">
								<p class="text-sm text-gray-400">Price</p>
								<p class="text-sm" v-if="product">${{product.price}}</p>
								<p class="text-sm w-12 h-5 skeleton" v-if="!product"></p>
							</div>							
						</div>
						<div class="pb-2 pt-8 font-bold text-xl">Recent Orders</div>
						<div class="w-full bg-base-100 border-t pt-2">      							
							<div v-if="!orders">
								<table class="table">            
									<thead>
										<tr class="">              
											<th>Order</th>
											<th>Date</th>
											<th>Client</th>								
										</tr>
									</thead>
									<tbody>              
										<tr class="" >   
											<td class="w-1/5">											
												<div class="w-12 h-5 skeleton"></div>
											</td>
											<td class="w-2/5">
												<div class="w-12 h-5 skeleton"></div>													
											</td>
											<td class="w-2/5">
												<div class="w-12 h-5 skeleton"></div>
											</td>
										</tr>
									</tbody>                               
								</table>
							</div>
							<div v-if="orders && orders.length == 0">
								There are no recent orders with this product
							</div>
							<div v-if="orders && orders.length > 0" class="overflow-x-auto">
								<table class="table">            
									<thead>
										<tr class="cursor-pointer">              
											<th>Order</th>
											<th>Date</th>
											<th>Client</th>								
										</tr>
									</thead>
									<tbody>              
										<tr v-for="order in orders" v-bind:key="order.id" class="hover:bg-base-200 cursor-pointer" @click="goTo(order)">   
											<td class="w-1/5">													
												{{order.id}}													
											</td>
											<td class="w-2/5">
												{{new Date(order.created_at).toLocaleDateString()}}
											</td>
											<td class="w-2/5">
												{{order.client.name}}
											</td>
										</tr>
									</tbody>                               
								</table>								
							</div>
						</div>
					</div>
					<div class="col-span-1 pt-8 md:pt-0">			
						<div class="pb-2 font-bold text-xl">Actions</div>
						<div class="w-full bg-base-100 border-t pt-2">      
							<div class="label cursor-pointer">
								<span class="label-text text-sm">Active</span> 
								<input v-if="product" @change="updateProduct(product, false)" v-model="product.active_temp" type="checkbox" class="toggle" checked />
								<input v-if="!product" type="checkbox" class="toggle skeleton" />
							</div>
						</div>
						<div class="pb-2 pt-8 font-bold text-xl">Image</div>
						<div class="w-full bg-base-100 border-t pt-2">      		
						<div v-if="product" class="rounded-lg w-full aspect-square bg-gray-300">
							<div  class="relative bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden" style=" aspect-ratio: 1 / 1 !important;" v-bind:style="{ backgroundImage: 'url(' + product.photo + ')' }">	
							</div>					
						</div>
						<div v-if="!product" class="skeleton rounded-lg w-full aspect-square bg-gray-300">
							<div  class="relative bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden" style=" aspect-ratio: 1 / 1 !important;">	
							</div>					
						</div>
						</div>
					</div>
				</div>
			</div>
		</div> 
		<ProductDrawer v-if="product" :product="product" :handler="handleProduct" :toggle="toggle" :deleteFile="deleteFile"></ProductDrawer>
	</div>
	
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import { PencilIcon } from '@heroicons/vue/24/outline' 
	import ProductDrawer from '@/components/ProductDrawer.vue' 
	import utils from "@/helpers/utils.js"
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_Product",	
		components: {
			PencilIcon, ProductDrawer
		},		
		setup() {
			const route = useRoute()
			const router = useRouter()

			const orders = ref(null)
			const product = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/product/${route.params.product_id}`)
				.then( res => { 
					product.value = res.data.product
					
					product.value.price_temp = res.data.product.price
					product.value.name_temp = res.data.product.name
					product.value.active_temp = res.data.product.active

				})
				.catch(e => {
					console.log(e)
				}) 

				axios.get(`/api/provider/${route.params.provider_id}/orders?product_id=${route.params.product_id}`)
				.then( res => { 
					orders.value = res.data.orders
				})
				.catch(e => {
					console.log(e)
				}) 
			})		

			async function updateProduct(updatedProduct, triggerToggle) {
				if (!updatedProduct.name) {
					return useToast().warning('A name is required for a product.', { position: "top-right" });
				}

				if (!updatedProduct.price) {
					return useToast().warning('A price is required for a product.', { position: "top-right" });
				}			

				let data = {
					price: updatedProduct.price,
					name: updatedProduct.name,
					active: updatedProduct.active_temp,
					photo: updatedProduct.photo
				}	

				console.log('photos', product.value.photo, updatedProduct.photo)
				if (product.value.photo && updatedProduct.photo == null) {
					data.photo = null
				}

				console.log('data', data)

				let loader = loading.show()
				axios.put(`/api/provider/${route.params.provider_id}/product/${route.params.product_id}`, data)
				.then( res => { 					
					product.value = res.data.product					
					product.value.active_temp = res.data.product.active

					useToast().success('The product has been updated.', { position: "top-right" });
					loader.hide()
					if (triggerToggle) {
						toggle()	
					}				
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error updating the product, please try again.', { position: "top-right" });
					console.log(e)
				}) 

				if (product.value.photo && updatedProduct.photo == null) {
					console.log('Deleting photo')
					let file = product.value.photo.split('public/')[product.value.photo.split('public/').length - 1]

					axios.delete(`/api/file?file=${file}`)
					.then( res => { 	
						console.log(res)				
						product.value.photo = null								
					})
					.catch(e => {
						console.log(e)
					}) 

				}
			} 

			function handleProduct(updatedProduct) {
				updateProduct(updatedProduct, true)
			}

			function toggle() {
				const checkbox = document.getElementById('product_drawer');
				checkbox.checked = !checkbox.checked;
			}

			function goTo(order) {
				router.push(`/provider/${route.params.provider_id}/order/${order.id}`)				
			}

			function deleteFile() {

			}

			return {
				route,
				router,
				utils,
				product,
				orders,
				toggle,
				updateProduct,
				handleProduct,
				goTo,
				ProductDrawer,
				deleteFile
			}
		}
	};
</script>

<template>
	<div class="p-4 lg:p-6 pt-0 lg:pt-6 h-full overflow-y-scroll">	
		<div class="text-2xl font-bold pb-4">New order</div>
		<select class="select select-bordered select-sm w-full mb-6" v-if="providerClients && providerClients.length > 0" v-model="providerClient">
			<option :value="null" disabled selected>Select a client</option>
			<option v-for="providerClient in providerClients" v-bind:key="providerClient.id" :value="providerClient">{{providerClient.client.name}}</option>
		</select>	
		<div class="card-bordered card-compact rounded-lg shrink-0 w-full bg-base-100" v-if="providerClients && providerClients.length == 0">
			<div class="card-body text-center">
				Add your first client to create your first order.
			</div>
		</div>
		<NewOrderComponent v-if="providerClient" :products="products" :handler="createOrder"/>		
	</div>
</template>

<script>	
	import { onMounted, ref } from "vue"
	import axios from "@/axios.js"
	import { useRoute, useRouter } from 'vue-router'
	import NewOrderComponent from "@/components/NewOrderComponent.vue";
	import utils from "@/helpers/utils.js"
	import { useLoading } from 'vue-loading-overlay'
	import { useToast } from 'vue-toast-notification';

	export default {
		name: "Provider_New_Order",			
		components: {
			NewOrderComponent
		},
		setup() {
			const route = useRoute()
			const router = useRouter()

			const products = ref(null)   							
			const providerClients = ref(null)
			const providerClient = ref(null)

			const loading = useLoading({
				color: "#4793AF",
				loader: "spinner"
			});

			onMounted( async () => {                
				axios.get(`/api/provider/${route.params.provider_id}/products`)
				.then( res => { 				
					console.log(res)	
					products.value = res.data.products.map( p => {
						p.quantity = 0
						return p
					})		
				})
				.catch(e => {
					console.log(e)
				}) 

				let loader = loading.show()
				axios.get(`/api/provider/${route.params.provider_id}/clients`)
				.then( res => { 	
					loader.hide()			
					console.log(res)	
					providerClients.value = res.data.provider_clients					
				})
				.catch(e => {
					loader.hide()
					console.log(e)
				}) 
			})						

			function createOrder(orderProducts) {
				if (orderProducts.filter( p => p.quantity > 0).length == 0) {
					return useToast().warning('At least one product is requiered for an order.', { position: "top-right" });
				}

				let data = { 				
					clientId: providerClient.value.client.id, 
					providerId: route.params.provider_id,
					products: orderProducts.filter( p => p.quantity > 0)
				}
				
				let loader = loading.show()
				axios.post('/api/order', {
					order: JSON.stringify(data) 
				})
				.then( res => { 				
					console.log(res)	
					useToast().success('The order has been created.', { position: "top-right" });
					loader.hide()
					router.push(`/provider/${route.params.provider_id}/order/${res.data.order.id}`)
				})
				.catch(e => {
					loader.hide()
					useToast().error('There was an error creating the order, please try again.', { position: "top-right" });
					console.log(e)
				}) 
			}

			return {
				route,
				router,
				utils,
				providerClients,
				providerClient,
				products,
				createOrder		
			}
		}
	};
</script>
